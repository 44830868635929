<template>
  <div>
    <loading :active.sync="loading"></loading>
    <v-row>
      <v-col cols="12">
        <!-- {{form.digitalImg}} -->

        <v-img
          :src="form.digitalImg ? form.digitalImg : '@/assets/elearning.png'"
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
          "
          @click="clicklink(form.digital_file_url)"
        />
      </v-col>
    </v-row>
    <!-- <a
      :href="`https://` + form.digital_file_url"
      target="_blank"
      rel="noopener noreferrer"
      >click hereeee</a
    > -->
    <v-divider class="my-2"></v-divider>
    <div style="color: #06204b" class="fn20-weight7">
      {{ form.digital_name }}
    </div>
    <span style="word-break: break-word; font-weight: 700" class="fn12">
      {{
        form.description
          ? form.description
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui non mauris lobortis congue. Ut eu dui vitae praesent dolor nulla id. Cursus placerat amet tortor tincidunt mauris quis. Malesuada enim in aliquet ornare tristique. Id enim hac facilisi vitae at augue venenatis, eleifend. Sagittis risus nunc odio diam facilisis amet. Sed tincidunt fusce nullam volutpat sapien. Morbi quam nec bibendum pharetra elit interdum scelerisque. Sem aliquam, sapien, accumsan turpis libero aliquam risus. Posuere praesent sit metus nibh eget posuere sit."
      }}
    </span>
    <p
      class="mt-4 text-decoration-underline fn14-weight7"
      style="color: #06204b"
    >
      Terms & conditions
    </p>
    <v-btn
      block
      color="#1A347F"
      elevation="2"
      large
      outlined
      x-large
      class="mt-8 rounded-lg"
      style="color: #1a347f"
      @click="cancel()"
    >
      <span class="font">Back</span>
    </v-btn>
    <v-dialog v-model="dialogSuccess">
      <v-card class="rounded-lg">
        <v-container>
          <v-row align="center" justify="center" class="ma-4 text-center dense">
            <v-col cols="12" class="my-4">
              <span style="font-weight: 900; font-size: 24px; color: #1a347f">
                Thank You For
              </span>
              <br />
              <span
                style="font-weight: 900; font-size: 24px; color: #1a347f"
                v-if="form.type === 'video'"
              >
                Waching Video!
              </span>
              <span
                style="font-weight: 900; font-size: 24px; color: #1a347f"
                v-else
              >
                Reading !
              </span>
            </v-col>
            <v-col cols="12">
              <v-row justify="center" align="center" dense>
                <v-col cols="12" class="px-0">
                  <img
                    src="@/assets/BI.png"
                    justify="right"
                    style="width: 50px; height: 50px"
                  />

                  <span
                    class="align-center"
                    style="font-weight: 800; font-size: 20px; color: #1a347f"
                  >
                    You Earn {{ form.point }} Points</span
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" dense>
            <v-col cols="12">
              <v-btn
                block
                large
                outlined
                x-large
                class="mt-4 rounded-lg buttoncolor"
                @click="cancel()"
              >
                <span class="font">Back to homepage</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    pdf,
    Loading,
  },

  data() {
    return {
      categoryName: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: [],

      frontId: "",
      form: {},
      dialogSuccess: false,
      src: null,
      numberOfPages: 0,
      loading: false,
    };
  },
  // mounted() {
  //   this.src.promise.then((pdf) => {
  //     this.numberOfPages = pdf.numPages;
  //   });
  // },

  async created() {
    // this.loading = true;
    // this.getPoint();
    const urlParams = this.$route.query;
    this.frontId = urlParams.reward;
    this.getOneFront();
    // if (this.form.digital_video_url) {
    //   this.loading = false;
    // }
    // await setTimeout(() => {

    // }, 2000);
  },
  methods: {
    clicklink(val) {
      window.open(val, "_blank");
      // window.open(`https://` + val, "_blank");
    },
    gotopdf(val) {
      window.open(val, "_blank");
    },
    countdown() {
      console.log("aaaaaaaaa");
      setTimeout(() => {
        this.getPoint();
      }, 2000);
    },
    async getPoint(e) {
      // console.log("getPoint");
      this.user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const data = {
        addPoint: this.form.point,
        digitalCategory3Id: this.form.digitalCategory3Id,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.user.id}`,
        data
      );
      console.log("response", response.data);
      // message: "User was updated successfully"
      // response_status: "SUCCESS"
      if (response.data.response_status == "SUCCESS") {
        this.dialogSuccess = true;
      }
    },
    async getOneFront() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital/${this.frontId}
          `
      );
      console.log("getOneKnowledge", response.data);
      this.form = response.data.data;
      console.log("aaaaaa", this.form.type);
      if (this.form.type == "image") {
        this.countdown();
      }
      if (this.form.type == "pdf") {
        this.src = pdf.createLoadingTask(this.form.digital_file_url);
        this.src.promise.then((pdf) => {
          this.numberOfPages = pdf.numPages;
        });
        this.countdown();
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiData"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          category: this.categoryName,
          description: this.categoryDescription,
          activeFlag: this.activeFlag,
          categoryImg: this.pic1,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/categories`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.font {
  font-family: "OpenSansBold" !important;
}
.buttoncolor {
  color: white;
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  height: 57px;
}
</style>
